<template>
  <div class="m-5 justify-center flex">
    <div
      class="bg-slate-100 rounded-md p-4 mt-14 mb-20 w-full sm:w-2/3 lg:w-1/3"
    >
      <h2 class="text-xl text-center mt-3 mb-6 uppercase block tracking-wide">
        Add Manager Details
      </h2>
      <form @submit.prevent="addManager" class="grid gap-4">
        <input
          v-model="firstName"
          type="text"
          placeholder="First Name"
          required
          class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
        />
        <input
          v-model="lastName"
          type="text"
          placeholder="Last Name"
          required
          class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
        />
        <input
          v-model="company"
          type="text"
          placeholder="Company Name"
          required
          class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
        />
        <input
          v-model="linkedin"
          type="text"
          placeholder="LinkedIn Profile Link"
          required
          class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
        />
        <div class="flex justify-center">
          <button
            type="submit"
            class="bg-blue-500 text-white p-2 rounded shadow-md hover:bg-blue-600 flex items-center"
          >
            <loading_er v-if="isSaving" class="animate-spin" />
            <span v-else>Add Manager</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import baseURL from "../../config.js";
import { useRouter } from "vue-router";
import loading_er from "@/assets/loading_er.vue";

const firstName = ref("");
const lastName = ref("");
const company = ref("");
const linkedin = ref("");
const router = useRouter();
const isSaving = ref(false);

const addManager = async () => {
  isSaving.value = true;
  const manager = {
    firstName: firstName.value,
    lastName: lastName.value,
    role: "manager",
    company: company.value,
    linkedin: linkedin.value,
  };

  try {
    const response = await fetch(`${baseURL.baseUrl}/add-manager`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(manager),
    });

    if (!response.ok) {
      throw new Error("Failed to add manager");
    }

    const data = await response.json();
    isSaving.value = false;
    alert("Manager added", data);
    router.push("/");

    // Clear form fields after submission
    firstName.value = "";
    lastName.value = "";
    company.value = "";

    // Emit event to notify parent component (if needed)
    this.$emit("managerAdded");
  } catch (error) {
    console.error("Error:", error);
    isSaving.value = false;
  }
};
</script>

<style scoped>
@media (min-width: 640px) {
  .sm\:m-5 {
    margin: 1.25rem;
  }
}

@media (min-width: 1024px) {
  .lg\:w-1\/3 {
    width: 33.333333%;
  }
}
</style>
