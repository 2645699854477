<template>
    <div class="flex items-center">
      <span v-for="n in 5" :key="n" class="text-yellow-400">
        <i
          :class="
            n <= Math.floor(validatedRating)
              ? 'fas fa-star'
              : n - validatedRating < 1
              ? 'fas fa-star-half-alt'
              : 'far fa-star'
          "
        ></i>
      </span>
      <span class="ml-2 text-gray-600">({{ validatedRating.toFixed(1) }})</span>
    </div>
  </template>
  
  <script setup>
  import { defineProps, computed } from "vue";
  
  const props = defineProps({
    rating: {
      type: [Number, String],
      required: true,
    },
  });
  
  // Ensure that the rating is a number, default to 0 if it's not valid
  const validatedRating = computed(() => {
    const numericRating = parseFloat(props.rating);
    return isNaN(numericRating) ? 0 : numericRating;
  });
  </script>
  
  <style scoped>
  .fas,
  .far {
    font-size: 1.25rem;
  }
  </style>
  