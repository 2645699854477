<template>
  <div>
    <div class="p-6">
      <h1 class="text-2xl font-bold mb-4">Welcome {{ user.firstname }}</h1>
      <p class="mb-5 font-sans">
        Rate and discover the best managers in your organization! Your feedback
        matters.
      </p>
      <ManagerList />
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import ManagerList from "../components/ManagerList.vue";
import { mainStore } from "../stores/index";
import { computed } from 'vue';

export default {
  name: "HomePage",
  components: {
    ManagerList,
  },
  setup() {
    const store = mainStore(); // Access the store
    const user = computed(() => store.user); // Get the user from the store

    return {
      user
    };
  }
};
</script>
