<template>
  <div
    class="footer bg-gradient-to-r from-gray-800 to-gray-600 p-6 sm:p-10 text-white font-thin"
  >
    <router-link to="/" class="text-xl uppercase block tracking-wide font-bold"
      >Rate My Manager</router-link
    >
    <p class="mt-5 font-sans">
      ➤ Join us in building a transparent and supportive workplace culture
      through constructive feedback.
    </p>
    <p class="mt-4 font-sans">
      ➤ Explore insights from peers and contribute to making informed decisions
      about leadership.
    </p>
    <p class="mt-4 font-sans">
      ➤ Empower managers with your honest ratings to foster continuous
      improvement and growth.
    </p>
    <p class="text-center mt-10">&copy; 2024 Rate My Manager. All rights reserved.</p>
  </div>
</template>

<script>
export default {
  name: "HeaderPage",
  computed: {
    headerClass() {
      // Define gradient colors here
      return "bg-gradient-to-r from-gray-800 to-gray-600";
    },
  },
};
</script>
<style scoped>

</style>
