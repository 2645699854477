<template>
  <div>
    <ManagerList />
  </div>
</template>

<script>
import ManagerList from "../components/ManagerList.vue";

export default {
  name: "RateManager",
  components: {
    ManagerList,
  },
};
</script>
