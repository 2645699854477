<template>
  <button
    class="sm:m-5 flex items-center mt-4 text-lg hover:text-blue-500 hover:rounded-md p-2"
    @click="goHome"
  >
    <back_button /> <span>Go Back</span>
  </button>
  <div
    class="p-6 sm:mt-4 max-w-4xl mx-auto bg-white shadow-lg rounded-lg"
    v-for="(manager, index) in manager"
    :key="index"
  >
    <div class="flex justify-between items-center gap-3">
      <h2 class="text-4xl font-bold mb-4">
        {{ manager.firstname }} {{ manager.lastname }}
      </h2>
      <a v-if="manager.linkedin"
        :href="manager.linkedin"
        target="_blank"
        rel="noopener noreferrer"
      >
        <linkedin_icon />
      </a>
    </div>
    <p class="text-lg mb-2">Company: {{ manager.org }}</p>
    <p class="text-lg mb-2">Role: {{ manager.role }}</p>
    <p class="text-lg mb-4 flex gap-2">
      <spam>Average Rating:</spam>
      <StarRating :rating="averageRating(manager.ratings)" />
    </p>
    <div class="flex justify-between mt-5">
      <p class="text-sm font-thin">Rated by {{ ratedCount(manager) }} people</p>
      <button
        @click="rateManager"
        class="bg-blue-500 p-3 rounded-md text-white italic hover:bg-blue-600 shadow-md"
      >
        Rate ☆
      </button>
    </div>
    <div class="my-4">
      <h3 class="text-2xl font-semibold mb-2">Comments:</h3>
      <ul>
        <li
          v-for="(comment, cIndex) in getComments(manager)"
          :key="cIndex"
          class="bg-gray-100 p-4 rounded-md mb-2"
        >
          <div class="flex justify-between items-center">
            <p class="italic">"{{ comment.comment }}"</p>
            <button
              @click="flagComment(manager.id, comment.id)"
              class="text-red-400 italic hover:text-red-800"
            >
              <flagVue />
            </button>
          </div>
          <p class="text-sm text-gray-500 mt-2">
            Posted on:
            {{
              new Date(comment.created_at).toLocaleDateString("en-US", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })
            }}
            at
            {{
              new Date(comment.created_at).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
              })
            }}
          </p>
        </li>
      </ul>
      <p v-if="getComments(manager).length === 0" class="italic">
        No comments yet.
      </p>
    </div>

    <!-- Rating Modal -->
    <div v-if="showModal" class="modal">
      <div class="modal-content sm:p-4">
        <span class="close" @click="showModal = false">&times;</span>
        <h2 class="text-xl text-center">Rate Manager</h2>
        <div class="flex justify-evenly m-5 flex-row-reverse">
          <!-- Updated here -->
          <span
            v-for="star in 5"
            :key="star"
            @click="setRating(6 - star)"
            :class="[
              'cursor-pointer',
              'star',
              6 - star <= rating ? 'selected' : 'unselected',
            ]"
          >
            <svg
              v-if="6 - star <= rating"
              xmlns="http://www.w3.org/2000/svg"
              class="h-8 w-8 text-yellow-500"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.286 3.971a1 1 0 00.95.691h4.18c.97 0 1.372 1.24.588 1.81l-3.37 2.454a1 1 0 00-.364 1.118l1.287 3.97c.3.921-.755 1.688-1.54 1.118l-3.37-2.454a1 1 0 00-1.175 0l-3.37 2.454c-.784.57-1.838-.197-1.539-1.118l1.286-3.97a1 1 0 00-.364-1.118L2.381 9.4c-.784-.57-.381-1.81.588-1.81h4.18a1 1 0 00.95-.691L9.049 2.927z"
              />
            </svg>
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              class="h-8 w-8 star"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.286 3.971a1 1 0 00.95.691h4.18c.97 0 1.372 1.24.588 1.81l-3.37 2.454a1 1 0 00-.364 1.118l1.287 3.97c.3.921-.755 1.688-1.54 1.118l-3.37-2.454a1 1 0 00-1.175 0l-3.37 2.454c-.784.57-1.838-.197-1.539-1.118l1.286-3.97a1 1 0 00-.364-1.118L2.381 9.4c-.784-.57-.381-1.81.588-1.81h4.18a1 1 0 00.95-.691L9.049 2.927z"
              />
            </svg>
          </span>
        </div>
        <textarea
          v-model="comment"
          class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
          placeholder="Enter your comments"
        ></textarea>
        <div>
          <button
            @click="submitRating"
            class="bg-blue-500 text-white p-2 mt-2 rounded-md shadow-md w-full hover:bg-blue-600"
          >
            <loading_er v-if="isSaving" class="animate-spin" />
            <div v-else>Submit</div>
          </button>
        </div>
      </div>
    </div>
    <!-- Flag Modal -->
    <div v-if="showFlagModal" class="modal">
      <div class="modal-content p-4">
        <span class="close" @click="showFlagModal = false">&times;</span>
        <h2 class="text-xl text-center">Flag Comment</h2>
        <div>
          <textarea
            v-model="flagReason"
            class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            placeholder="Enter the reason for flagging"
          ></textarea>
          <div>
            <button
              @click="submitFlag"
              class="bg-red-500 text-white p-2 mt-2 rounded-md shadow-md w-full hover:bg-red-600"
            >
              <loading_er v-if="isFlagging" class="animate-spin" />
              <div v-else>Submit Flag</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="justify-center flex m-5">
    <loading_er v-if="isLoading" />
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import baseURL from "../../config.js";
import loading_er from "@/assets/loading_er.vue";
import { mainStore } from "../stores/index";
// import Flag from '../assets/flag_comment_comment.vue'
import flagVue from "@/assets/flag_comment.vue";
import back_button from "@/assets/back_button.vue";
import StarRating from "./StarRating.vue";
import linkedin_icon from "@/assets/linkedin_icon.vue";

const router = useRouter();
const route = useRoute();
const manager = ref(null);
const showModal = ref(false);
const showFlagModal = ref(false);
const selectedManager = ref(null);
const selectedComment = ref(null);
const rating = ref(0);
const comment = ref("");
const flagReason = ref("");
const isSaving = ref(false);
const isFlagging = ref(false);
const isLoading = ref(false);

const loadManagerDetails = async () => {
  isLoading.value = true;

  try {
    const response = await fetch(
      `${baseURL.baseUrl}/manager/${route.params.managerId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch manager details");
    }
    manager.value = await response.json();
    isLoading.value = false;
  } catch (error) {
    console.error("Error loading manager details:", error);
  }
};

const rateManager = (index) => {
  const user = mainStore().user;
  if (user && Object.keys(user).length > 0) {
    selectedManager.value = index;
    showModal.value = true;
    rating.value = 0; // Reset rating value when the modal is opened
    comment.value = "";
  } else {
    alert("You must be logged in to rate a manager.");
  }
};

function goHome() {
  router.push("/home");
}

const setRating = (star) => {
  rating.value = star;
};

const submitRating = async () => {
  isSaving.value = true;

  if (rating.value >= 1 && rating.value <= 5) {
    const managerToUpdate = manager.value[0];
    const userId = mainStore().user.id;
    console.log("manager to update", managerToUpdate.ratings);
    managerToUpdate.ratings.push({
      rating: rating.value,
      comment: comment.value.trim(),
      created_at: new Date().toISOString(),
    });

    try {
      const response = await fetch(
        `${baseURL.baseUrl}/managers/${managerToUpdate.id}/rate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            rating: rating.value,
            comment: comment.value.trim(),
            userId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit rating");
      }
      isSaving.value = false;

      const updatedManager = await response.json();
      manager.value[selectedManager.value] = updatedManager;
      window.location.reload();
    } catch (error) {
      console.error("Error submitting rating:", error);
    }

    showModal.value = false;
    rating.value = null;
    comment.value = "";
  }
};

const flagComment = (managerId, commentId) => {
  const user = mainStore().user;
  if (user && Object.keys(user).length > 0) {
    console.log("user present");
    selectedManager.value = managerId;
    selectedComment.value = commentId;
    showFlagModal.value = true;
    flagReason.value = "";
  } else {
    alert("Please login to flag a comment");
  }
};

const submitFlag = async () => {
  isFlagging.value = true;
  const userId = mainStore().user.id;

  try {
    const response = await fetch(`${baseURL.baseUrl}/flag-rating`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        rating_id: selectedComment.value,
        reason: flagReason.value.trim(),
        user_id: userId,
      }),
    });
    alert("Submitted");

    if (!response.ok) {
      throw new Error("Failed to flag comment");
    }

    isFlagging.value = false;
    showFlagModal.value = false;
  } catch (error) {
    console.error("Error flagging comment:", error);
  }
};

const averageRating = (ratings) => {
  if (ratings.length === 0) return "No ratings";
  console.log(ratings, "rate");
  const total = ratings.reduce((acc, ratingObj) => acc + ratingObj.rating, 0);
  return (total / ratings.length).toFixed(1);
};

const ratedCount = (manager) => {
  return manager.ratings.length;
};

const getComments = (manager) => {
  return manager.ratings
    .filter((ratingObj) => ratingObj.comment && !ratingObj.is_flagged)
    .map((ratingObj) => ({
      id: ratingObj.id,
      comment: ratingObj.comment,
      created_at: ratingObj.created_at, // Include created_at
    }));
};

onMounted(loadManagerDetails);

// Functions like rateManager, submitRating, flagComment, submitFlag, averageRating, ratedCount, getComments
// similar to your previous implementation.
</script>

<style scoped>
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 20px;
  box-sizing: border-box;
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 100%;
  max-width: 500px; /* Ensure modal doesn't get too wide on large screens */
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.close {
  color: #aaa;
  float: right;
  font-size: 24px;
  font-weight: bold;
  margin-top: -10px; /* Adjust for better alignment on mobile */
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

textarea {
  resize: none;
  width: 100%;
  height: 100px;
  font-size: 1rem;
}

.star {
  transition: color 0.2s ease, transform 0.2s ease;
}

.star:hover,
.star:hover ~ .star {
  color: #fbbf24; /* Hover color for the stars */
  transform: scale(1.2); /* Scale up the star on hover */
}

.star.selected {
  color: #fbbf24; /* Color for selected stars */
}

.star.unselected {
  color: #e5e7eb; /* Color for unselected stars */
}

@media (max-width: 640px) {
  .modal-content {
    width: 90%;
    padding: 15px;
  }

  .close {
    font-size: 20px;
  }

  textarea {
    height: 80px;
    font-size: 0.875rem;
  }
}
</style>
