<template>
  <div class="company-detail-page">
    <div
      class="company-info-card shadow-lg p-6 rounded-lg bg-white border border-gray-300"
    >
      <h1 class="text-4xl font-bold text-gray-800">{{ companyName }}</h1>
      <p class="text-lg text-gray-600 mt-2">Details for {{ companyName }}</p>
    </div>

    <!-- Managers Section -->
    <div class="managers-list mt-10">
      <h2 class="text-2xl font-semibold mb-4 text-blue-600">Managers</h2>
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        <div
          v-for="manager in managers"
          :key="manager.id"
          class="manager-card flex flex-col items-start p-6 mb-4 shadow-md border rounded-lg bg-white hover:bg-gray-50 transition-all"
        >
          <div class="flex justify-between w-full">
            <div class="manager-info flex flex-col">
              <p class="font-semibold text-xl text-gray-800">
                {{ manager.firstname }} {{ manager.lastname }}
              </p>
              <p class="text-sm text-gray-600">{{ manager.role }}</p>
              <p v-if="manager.phone" class="text-gray-500 text-sm mt-1">
                Phone: {{ manager.phone }}
              </p>
            </div>
            <div>
              <a
                v-if="manager.linkedin"
                :href="manager.linkedin"
                class="text-blue-500 hover:text-blue-700 transition"
                target="_blank"
                aria-label="LinkedIn Profile"
              >
                <linkedin_icon />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!managers.length" class="text-gray-500 mt-2">
        No managers available for this company.
      </div>
    </div>

    <!-- Users Section -->
    <div class="users-list mt-10">
      <h2 class="text-2xl font-semibold mb-4 text-blue-600">Users</h2>
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        <div
          v-for="user in users"
          :key="user.id"
          class="user-card flex flex-col items-start p-6 mb-4 shadow-md border rounded-lg bg-white hover:bg-gray-50 transition-all"
        >
          <div class="user-info">
            <p class="font-semibold text-xl text-gray-800">
              {{ user.firstname }} {{ user.lastname }}
            </p>
            <!-- <p class="text-sm text-gray-600">{{ user.role }}</p> -->
            <p v-if="user.email" class="text-gray-500 text-sm mt-1">
              Email: {{ user.email }}
            </p>
            <p v-if="user.phone" class="text-gray-500 text-sm mt-1">
              Phone: {{ user.phone }}
            </p>
          </div>
        </div>
      </div>
      <div v-if="!users.length" class="text-gray-500 mt-2">
        No users available for this company.
      </div>
    </div>
    <div class="justify-center flex m-5">
      <loading_er v-if="isLoading" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import baseURL from "../../config.js";
import loading_er from "@/assets/loading_er.vue";
import linkedin_icon from "@/assets/linkedin_icon.vue";

const companyName = ref("");
const managers = ref([]);
const users = ref([]);
const route = useRoute();
const isLoading = ref(false);

const fetchCompanyDetails = async () => {
  const companyId = route.params.companyId;
  isLoading.value = true;

  try {
    const res = await fetch(`${baseURL.baseUrl}/company-detail/${companyId}`);
    const data = await res.json();

    if (data.length) {
      companyName.value = data[0].org;
    }

    managers.value = data.filter((person) => person.role === "manager");
    users.value = data.filter((person) => person.role === "user");
    isLoading.value = false;

  } catch (error) {
    console.error("Error fetching company details:", error);
  }
};

onMounted(fetchCompanyDetails);
</script>

<style scoped>
.company-detail-page {
  font-family: "Inter", sans-serif;
  padding: 20px;
}

.company-info-card {
  background-color: #ffffff;
}

.managers-list,
.users-list {
  padding: 20px;
  background-color: #fafafa;
  border-radius: 10px;
}

.manager-card,
.user-card {
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}

.manager-card:hover,
.user-card:hover {
  background-color: #f0f4ff;
}

h2 {
  border-bottom: 2px solid #d1d5db;
  padding-bottom: 8px;
}

@media (max-width: 640px) {
  .company-info-card {
    padding: 15px;
  }

  .managers-list,
  .users-list {
    padding: 10px;
  }

  .manager-card,
  .user-card {
    padding: 10px;
  }
}
</style>
