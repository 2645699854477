<template>
  <div class="managers-section">
    <h2 class="section-title">Top Managers</h2>

    <div class="best-of">
      <div class="highlight-card">
        <h3>Best of the Month</h3>
        <p class="highlight">
          {{ bestOfMonth.name }}
          <span class="rating">({{ bestOfMonth.rating }})</span>
        </p>
      </div>
      <div class="highlight-card">
        <h3>Best of the Year</h3>
        <p class="highlight">
          {{ bestOfYear.name }}
          <span class="rating">({{ bestOfYear.rating }})</span>
        </p>
      </div>
    </div>

    <table class="managers-table">
      <thead>
        <tr>
          <th>Manager</th>
          <th>Rating</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="manager in managers" :key="manager.id">
          <td>{{ manager.full_name }}</td>
          <td>{{ parseFloat(manager.average_rating).toFixed(1) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="justify-center flex m-5">
    <loading_er v-if="isLoading" />
  </div>
</template>
<script>
import baseURL from "../../config.js";
import loading_er from "@/assets/loading_er.vue";

export default {
  components: {
    loading_er,
  },
  data() {
    return {
      bestOfMonth: {
        name: "Alice Garcia",
        rating: 3.7,
      },
      bestOfYear: {
        name: "Stephen Bob",
        rating: 4.0,
      },
      managers: [],
      isLoading: false,
    };
  },
  created() {
    this.fetchManagers();
  },
  methods: {
    async fetchManagers() {
      this.isLoading = true;
      try {
        const response = await fetch(`${baseURL.baseUrl}/managers`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        this.managers = await response.json();
        console.log("res", this.managers);
        this.isLoading = false;

      } catch (error) {
        console.error("Error fetching managers:", error);
      }
    },
  },
};
</script>

<style scoped>
.managers-section {
  margin: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.section-title {
  font-size: 28px;
  margin-bottom: 20px;
  color: #333;
  font-weight: bold;
}

.best-of {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.highlight-card {
  flex: 1;
  margin: 0 10px;
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.highlight-card h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.highlight {
  font-size: 18px;
  font-weight: bold;
}

.rating {
  color: #f39c12;
}

.managers-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
  max-width: 600px;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.managers-table th,
.managers-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.managers-table th {
  background-color: #284051;
  color: #fff;
  font-weight: bold;
}

.managers-table td {
  background-color: #fff;
}

@media (max-width: 768px) {
  .best-of {
    flex-direction: column;
  }

  .highlight-card {
    margin-bottom: 10px;
  }
}
</style>
