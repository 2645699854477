<template>
  <div class="rounded-lg">
    <div class="sm:flex justify-between items-center">
      <h2 class="font-bold text-2xl">Managers List</h2>

      <!-- <div class="flex items-center space-x-4 mt-5 sm:mt-0">
        <label>
          <input
            type="radio"
            v-model="searchType"
            value="managers"
            class="mr-2"
          />
          Managers
        </label>
        <label>
          <input
            type="radio"
            v-model="searchType"
            value="companies"
            class="mr-2"
          />
          Companies
        </label>
      </div> -->

      <!-- <div
        class="w-full sm:w-2/5 sm:mt-4 mt-5 pl-5 sm:mx-4 sm:py-2 pr-8 relative flex justify-between items-center h-fit z-10 bg-white shadow-lg border border-blue-400 rounded-full"
      >
        <SearchIcon class="w-5 h-5" />
        <input
          v-model="searchInput"
          :placeholder="`Search for ${searchType}`"
          class="ml-2 px-2 py-1 w-full focus:outline-none rounded-lg"
          @input="homePageSearch"
        />
        <CloseIcon
          v-if="searchInput"
          class="w-7 h-7 ml-1 cursor-pointer"
          @click="closeSearchResults"
        />
        <div
          v-if="isSearchResultsVisible"
          class="ml-2 top-full w-11/12 absolute z-10 bg-white shadow-2xl scroll-auto overflow-auto"
        >
          <div
            v-for="(result, index) in searchType === 'managers'
              ? searchResults.managers.managers
              : searchResults.companies.companies"
            :key="index"
            class="p-2 px-10 flex justify-between items-center cursor-pointer border-b font-light hover:bg-blue-200"
            :class="{ 'bg-red-200': searchIndex === index }"
            @click="redirectToSelected(result)"
          >
            <div class="flex items-center gap-5">
              <div>
                <div class="font-medium text-sm">
                  {{
                    searchType === "managers"
                      ? result.firstname + " " + result.lastname
                      : result.company_name
                  }}
                </div>
                <div class="hidden sm:flex text-xs text-gray-900">
                  {{ searchType === "managers" ? result.role : result.role }}
                </div>
              </div>
            </div>
            <div class="flex justify-center">
              \
              <div
                class="px-5 h-6 rounded-2xl text-base bg-blue-600 text-white"
              >
                {{ searchType === "companies" ? result.role : result.org }}
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <ul class="text-lg font-thin">
      <li
        v-for="(manager, index) in managers"
        :key="index"
        class="mb-4 p-5 bg-slate-200 shadow-lg flex justify-between mt-5 rounded-md"
      >
        <div>
          <h3 class="font-bold">
            {{ manager.firstname }} {{ manager.lastname }}
          </h3>
          <p>Company: {{ manager.org }}</p>
          <p>Role: {{ manager.role }}</p>
          <StarRating :rating="averageRating(manager.ratings)" />
          <p class="text-sm mt-3">Rated by {{ ratedCount(manager) }} people</p>
        </div>
        <div class="w-1/4 justify-end flex h-14">
          <router-link
            :to="{ name: 'ManagerDetail', params: { managerId: manager.id } }"
            class="no-underline"
          >
            <button
              class="bg-blue-500 text-sm p-2 rounded-md text-white hover:bg-blue-600 shadow-md flex items-center gap-2"
            >
              <span>View</span> <eye_button />
            </button>
          </router-link>
        </div>
      </li>
    </ul>
    <div class="justify-center flex mt-5">
      <loading_er v-if="isFlagging" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import baseURL from "../../config.js";
import loading_er from "@/assets/loading_er.vue";
import eye_button from "@/assets/eye_button.vue";
// import SearchIcon from "@/assets/search_icon.vue";
// import CloseIcon from "@/assets/close_icon.vue";
import StarRating from "./StarRating.vue";
// import { useRouter } from "vue-router";

const managers = ref([]);
// const router = useRouter();
// const searchResults = ref({
//   managers: [],
//   companies: [],
// });
// const searchInput = ref("");
// const searchIndex = ref(-1);
// const isSearchResultsVisible = ref(false);
const isFlagging = ref(false);

// const searchType = ref("managers");

// const homePageSearch = async () => {
//   const key = searchInput.value.trim();
//   if (key === "") {
//     searchResults.value[searchType.value] = [];
//     isSearchResultsVisible.value = false;
//     return;
//   }

//   try {
//     const res = await fetch(
//       `${baseURL.baseUrl}/manager-list/autocomplete?key=${key}&searchType=${searchType.value}`
//     );
//     const data = await res.json();
//     searchResults.value[searchType.value] = data;
//     console.log("Search Results", searchResults.value);

//     if (searchResults.value[searchType.value]) {
//       isSearchResultsVisible.value = true;
//     }
//   } catch (error) {
//     console.error("Error fetching search results:", error);
//   }
// };

// const closeSearchResults = () => {
//   searchInput.value = "";
//   searchResults.value[searchType.value] = [];
//   isSearchResultsVisible.value = false;
// };

// const redirectToSelected = (result) => {
//   if (searchType.value === "managers") {
//     router.push({ name: "ManagerDetail", params: { managerId: result.id } });
//   } else {
//     // Logic for redirecting to company detail if necessary
//   }
// };

const loadManagers = async () => {
  isFlagging.value = true;
  try {
    const response = await fetch(`${baseURL.baseUrl}/manager-list`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    managers.value = await response.json();
  } catch (error) {
    console.error("Error loading managers:", error);
  } finally {
    isFlagging.value = false;
  }
};

const averageRating = (ratings) => {
  if (ratings.length === 0) return 0;
  const total = ratings.reduce((acc, ratingObj) => acc + ratingObj.rating, 0);
  return total / ratings.length;
};

const ratedCount = (manager) => {
  return manager.ratings.length;
};

onMounted(loadManagers);
</script>
