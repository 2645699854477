<template>
  <div class="home-page">
    <header class="hero-section">
      <h1>Welcome to the Rate My Manager</h1>
      <p>
        Rate and review managers with ease. Your feedback matters to help
        improve workplace leadership and create a better environment for all.
      </p>
    </header>

    <!-- Search Input with Radio Button Selection -->
    <div class="flex justify-center items-center space-x-4 mt- sm:mt-0">
      <label>
        <input
          type="radio"
          v-model="searchType"
          value="managers"
          class="mr-2"
        />
        Managers
      </label>
      <label>
        <input
          type="radio"
          v-model="searchType"
          value="companies"
          class="mr-2"
        />
        Companies
      </label>
    </div>

    <div class="flex justify-center mb-5">
      <div
        class="w-full sm:w-1/2 sm:mt-2 m-3 sm:m-2 pl-5 sm:py-2 pr-8 relative flex justify-between items-center h-fit z-10 bg-white shadow-lg border border-blue-400 rounded-full"
      >
        <search_icon class="w-5 h-5" />
        <input
          v-model="searchInput"
          :placeholder="`Search for ${searchType}`"
          class="ml-2 px-2 py-1 w-full focus:outline-none rounded-lg"
          @input="homePageSearch"
        />
        <CloseIcon
          v-if="searchInput"
          class="w-7 h-7 ml-1 cursor-pointer"
          @click="closeSearchResults"
        />
        <div
          v-if="isSearchResultsVisible"
          class="ml-2 top-full w-11/12 absolute z-10 bg-white shadow-2xl scroll-auto overflow-auto"
        >
          <div
            v-for="(result, index) in searchType === 'managers'
              ? searchResults.managers.managers
              : searchResults.companies.companies"
            :key="index"
            class="p-2 px-10 flex justify-between items-center cursor-pointer border-b font-light hover:bg-blue-200"
            :class="{ 'bg-red-200': searchIndex === index }"
            @click="redirectToSelected(result)"
          >
            <div class="flex items-center gap-5">
              <div>
                <div class="font-medium text-sm">
                  <!-- Correctly show company_name or manager's name -->
                  {{
                    searchType === "managers"
                      ? result.firstname + " " + result.lastname
                      : result.company_name
                  }}
                </div>
                <div class="hidden sm:flex text-xs text-gray-900" v-if="searchType === 'managers'">
                  <!-- Display role or company-related information -->
                  {{ searchType === "managers" ? result.role : result.role }}
                </div>
              </div>
            </div>
            <div class="flex justify-center" v-if="searchType === 'managers'">
              <!-- Fix the display of company name -->
              <div
                class="px-5 h-6 rounded-2xl text-base bg-blue-600 text-white"
              >
                {{ result.org }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

<div class="flex justify-center mb-10">
      <router-link to="/home" class="cta-button">Rate a Manager</router-link>
  
</div>
  </div>
</template>

<script setup>
import search_icon from "@/assets/search_icon.vue";
import baseURL from "../../config.js";
import { ref } from "vue";
import { useRouter } from "vue-router";

const searchResults = ref({
  managers: [],
  companies: [],
});
const searchType = ref("managers");
const searchInput = ref("");
const router = useRouter();
const searchIndex = ref(-1);
const isSearchResultsVisible = ref(false);
const homePageSearch = async () => {
  const key = searchInput.value.trim();
  if (key === "") {
    searchResults.value[searchType.value] = [];
    isSearchResultsVisible.value = false;
    return;
  }

  try {
    const res = await fetch(
      `${baseURL.baseUrl}/manager-list/autocomplete?key=${key}&searchType=${searchType.value}`
    );
    const data = await res.json();
    searchResults.value[searchType.value] = data;
    console.log("Search Results", searchResults.value);

    if (searchResults.value[searchType.value]) {
      isSearchResultsVisible.value = true;
    }
  } catch (error) {
    console.error("Error fetching search results:", error);
  }
};
const redirectToSelected = (result) => {
  if (searchType.value === "managers") {
    router.push({ name: "ManagerDetail", params: { managerId: result.id } });
  } else if (searchType.value === "companies") {
    router.push({ name: "CompanyDetail", params: { companyId: result.org } });
  }
};

</script>

<style scoped>
.home-page {
  font-family: "Arial", sans-serif;
  color: #333;
}

.hero-section {
  background-size: cover;
  background-position: center;
  text-align: center;
  padding: 60px 20px;
  color: black;
}

.hero-section h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.hero-section p {
  font-size: 1.2rem;
  margin-bottom: 0px;
}

.cta-button {
  background-color: #244459;
  color: #fff;
  padding: 10px 20px;
  border-radius: 25px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #2980b9;
}

.features-section {
  padding: 60px 20px;
  background-color: #f7f7f7;
  text-align: center;
}

.features-section h2 {
  font-size: 2rem;
  margin-bottom: 40px;
}

.features {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.feature-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  width: 300px;
  text-align: center;
}

.feature-card img {
  max-width: 100px;
  margin-bottom: 20px;
}

.feature-card h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.feature-card p {
  font-size: 1rem;
  color: #666;
}

.footer-section {
  text-align: center;
  padding: 20px;
  background-color: #333;
  color: #fff;
}

.home-link {
  color: #fff;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: bold;
}
</style>
