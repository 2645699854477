// import { createApp } from "vue";
// import App from "./App.vue";
// import router from "./router";
// import '../src/index.css';

// createApp(App).use(router).mount("#app");


import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import '../src/index.css';
import axios from 'axios';
import config from '../config.js';
import VueClickAway from 'vue3-click-away';
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";


const app = createApp(App);

app.config.globalProperties.$http = axios.create({
  baseURL: config.baseUrl,
});

app.provide('$http', app.config.globalProperties.$http);

app.use(createPinia());
app.use(router);
app.use(VueClickAway);


app.mount('#app');





