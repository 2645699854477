<template>
  <div class="flex justify-center">
    <div class="bg-slate-100 rounded-md p-4 mt-14 mb-14">
      <h2 class="text-xl text-center mt-3 mb-6 uppercase block tracking-wide">
        Create Profile
      </h2>
      <form @submit.prevent="createProfile">
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            >
              First Name
            </label>
            <input
              v-model="firstName"
              type="text"
              placeholder="Enter your first name"
              required
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            />
          </div>
          <div class="w-full md:w-1/2 px-3">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            >
              Last Name
            </label>
            <input
              v-model="lastName"
              type="text"
              placeholder="Enter your last name"
              required
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            />
          </div>
        </div>
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full md:w-full px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            >
              Email Address
            </label>
            <input
              v-model="email"
              type="email"
              placeholder="Enter your email address"
              required
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            />
          </div>
        </div>
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full md:w-full px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            >
              Phone Number
            </label>
            <input
              v-model="phoneNumber"
              type="tel"
              placeholder="Enter your phone number"
              required
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            />
          </div>
        </div>
        <button
          type="submit"
          class="bg-blue-500 text-white p-2 mt-2 w-full rounded-md hover:bg-blue-600 shadow-md"
        >
          Create Profile
        </button>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import baseURL from '../../config.js';

const firstName = ref('');
const lastName = ref('');
const email = ref('');
const phoneNumber = ref('');

const createProfile = async () => {
  const profile = {
    firstName: firstName.value,
    lastName: lastName.value,
    email: email.value,
    phoneNumber: phoneNumber.value,
  };

  try {
    const response = await fetch(`${baseURL.baseUrl}/add-user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(profile),
    });

    if (!response.ok) {
      throw new Error('Failed to create profile');
    }

    const data = await response.json();
    console.log('Profile created:', data);

    // Clear form fields after submission
    firstName.value = '';
    lastName.value = '';
    email.value = '';
    phoneNumber.value = '';

    // Emit event to notify parent component (if needed)
    this.$emit("profileCreated");
  } catch (error) {
    console.error('Error:', error);
  }
};
</script>



<style scoped>
/* Add your scoped styles here */
</style>
