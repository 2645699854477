<template>
  <div class="sm:p-6 p-3 mt-6 mb-32">
    <h1 class="sm:text-2xl font-bold mb-4">Admin Panel</h1>

    <!-- Tabs Navigation -->
    <div class="mb-6">
      <div class="text-sm flex gap-3 sm:space-x-4">
        <button
          :class="{
            'bg-gray-900 text-white': activeTab === 'profiles',
            'text-gray-900': activeTab !== 'profiles',
          }"
          @click="activeTab = 'profiles'"
          class="py-2 sm:px-4 px-1 rounded-md hover:bg-gray-200 transition-colors"
        >
          User Profiles
        </button>
        <button
          :class="{
            'bg-gray-900 text-white': activeTab === 'managers',
            'text-gray-900': activeTab !== 'managers',
          }"
          @click="activeTab = 'managers'"
          class="py-2 sm:px-4 px-1 rounded-md hover:bg-gray-200 transition-colors"
        >
          Managers
        </button>
        <button
          :class="{
            'bg-gray-900 text-white': activeTab === 'ratings',
            'text-gray-900': activeTab !== 'ratings',
          }"
          @click="activeTab = 'ratings'"
          class="py-2 sm:px-4 px-1 rounded-md hover:bg-gray-200 transition-colors"
        >
          Ratings
        </button>
        <button
          :class="{
            'bg-gray-900 text-white': activeTab === 'flagged',
            'text-gray-900': activeTab !== 'flagged',
          }"
          @click="activeTab = 'flagged'"
          class="py-2 sm:px-4 px-1 rounded-md hover:bg-gray-200 transition-colors"
        >
          Flagged Ratings
        </button>
      </div>
    </div>

    <!-- Display Profiles Tab -->
    <div v-if="activeTab === 'profiles'">
      <div v-if="profiles.length === 0" class="text-lg text-gray-600">
        No profiles found.
      </div>
      <div class="overflow-x-auto" v-else>
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Name
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Email
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Phone Number
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Organization
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="(profile, index) in profiles" :key="index">
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="flex items-center">
                  <div class="ml-4">
                    <div class="text-sm font-medium text-gray-900">
                      {{ profile.firstname }} {{ profile.lastname }}
                    </div>
                  </div>
                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm text-gray-900">{{ profile.email }}</div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm text-gray-900">{{ profile.phone }}</div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm text-gray-900">{{ profile.org }}</div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
                <button
                  @click="confirmDeleteProfile(profile.id, index)"
                  class="text-red-600 hover:text-red-900 transition-colors"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Display Managers Tab -->
    <div v-if="activeTab === 'managers'">
      <div v-if="managers.length === 0" class="text-lg text-gray-600">
        No managers found.
      </div>
      <div v-else class="overflow-x-auto">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Name
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Company
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Role
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Average Rating
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                No of Ratings
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="(manager, index) in managers" :key="index">
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="flex items-center">
                  <div class="ml-4">
                    <div class="text-sm font-medium text-gray-900">
                      {{ manager.firstname }} {{ manager.lastname }}
                    </div>
                  </div>
                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm text-gray-900">{{ manager.org }}</div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm text-gray-900">{{ manager.role }}</div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm text-gray-900">
                  <StarRating :rating="averageRating(manager.ratings)" />

                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm text-gray-900">
                  {{ ratedCount(manager) }}
                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
                <button
                  @click="confirmDeleteManager(manager.id, index)"
                  class="text-red-600 hover:text-red-900 transition-colors"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Display Ratings Tab -->
    <div v-if="activeTab === 'ratings'">
      <div v-if="ratings.length === 0" class="text-lg text-gray-600">
        No ratings found.
      </div>
      <div v-else class="overflow-x-auto">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Manager Name
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Rating
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Comment
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="(rating, index) in ratings" :key="index">
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm text-gray-900">
                  {{ rating.manager_name }}
                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm text-gray-900">
                  {{ rating.rating }} Star Rating
                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm text-gray-900">
                  {{ rating.comment ? rating.comment : "No Comments" }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Display Flagged Ratings Tab -->
    <div v-if="activeTab === 'flagged'">
      <div v-if="flaggedRatings.length === 0" class="text-lg text-gray-600">
        No flagged ratings found.
      </div>
      <div v-else class="overflow-x-auto">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Manager Name
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Rating
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Flagged Comment
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Reason for Flag
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="(rating, index) in flaggedRatings" :key="index">
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm text-gray-900">
                  {{ rating.manager_name }}
                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm text-gray-900">
                  {{ rating.rating }} Star Rating
                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm text-gray-900">
                  {{ rating.comment ? rating.comment : "No Comments" }}
                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm text-gray-900">
                  {{ rating.flag_reason ? rating.flag_reason : "No Reason" }}
                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
                <button
                  @click="confirmFlagRating(rating)"
                  :class="{
                    'text-green-600 hover:text-green-900': rating.is_flagged,
                    'text-red-600 hover:text-red-900': !rating.is_flagged,
                  }"
                  class="transition-colors"
                >
                  {{ getFlagButtonText(rating) }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="justify-center flex mt-5">
      <loading_er v-if="isFlagging" />
    </div>
  </div>
</template>

<script>
import baseURL from "../../config.js";
import { mainStore } from "../stores/index";
import loading_er from "@/assets/loading_er.vue";
import StarRating from "@/components/StarRating.vue";

export default {
  components: {
    loading_er,
    StarRating,
  },
  data() {
    return {
      profiles: [],
      managers: [],
      ratings: [],
      isFlagging: false,
      activeTab: "profiles", // Default active tab
    };
  },
  created() {
    this.fetchProfiles();
    this.fetchManagers();
    this.fetchRatings();
  },
  methods: {
    async fetchProfiles() {
      this.isFlagging = true;
      try {
        const response = await fetch(`${baseURL.baseUrl}/get-users`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        this.profiles = await response.json();
        this.isFlagging = false;
      } catch (error) {
        console.error("Error fetching profiles:", error);
      }
    },
    async fetchManagers() {
      try {
        const response = await fetch(`${baseURL.baseUrl}/manager-list`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        this.managers = await response.json();
      } catch (error) {
        console.error("Error fetching managers:", error);
      }
    },
    async fetchRatings() {
      try {
        const response = await fetch(`${baseURL.baseUrl}/get-ratings`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        this.ratings = await response.json();
        console.log("Ratings", this.ratings);
      } catch (error) {
        console.error("Error fetching ratings:", error);
      }
    },
    averageRating(ratings) {
      if (ratings.length === 0) return "No ratings";
      const total = ratings.reduce(
        (acc, ratingObj) => acc + ratingObj.rating,
        0
      );
      return (total / ratings.length).toFixed(1);
    },
    async deleteProfile(id, index) {
      try {
        const response = await fetch(`${baseURL.baseUrl}/delete-user/${id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          this.profiles.splice(index, 1); // Remove profile from local data array
        } else {
          console.error("Error deleting profile:", await response.json());
        }
      } catch (error) {
        console.error("Error deleting profile:", error);
      }
    },
    async deleteManager(id, index) {
      try {
        const response = await fetch(
          `${baseURL.baseUrl}/delete-manager/${id}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.ok) {
          this.managers.splice(index, 1); // Remove manager from local data array
        } else {
          console.error("Error deleting manager:", await response.json());
        }
      } catch (error) {
        console.error("Error deleting manager:", error);
      }
    },
    ratedCount(manager) {
      return manager.ratings.length;
    },
    confirmDeleteProfile(profileId, index) {
      if (confirm("Are you sure you want to delete this profile?")) {
        this.deleteProfile(profileId, index);
      }
    },
    confirmDeleteManager(managerId, index) {
      if (confirm("Are you sure you want to delete this manager?")) {
        this.deleteManager(managerId, index);
      }
    },
    confirmFlagRating(rating) {
      if (
        confirm(
          "Are you sure you want to toggle the flag status of this rating?"
        )
      ) {
        this.flagRating(rating);
      }
    },
    async flagRating(rating) {
      try {
        const response = await fetch(`${baseURL.baseUrl}/isFlag`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            rating_id: rating.rating_id,
            reason: "Flagging from Admin Panel",
            user_id: mainStore().user.id, // Assuming user information is stored in mainStore
            comment: "",
          }),
        });

        if (response.ok) {
          rating.is_flagged = !rating.is_flagged;
          // rating.flagged_id = rating.is_flagged ? mainStore().user.id : null;
        } else {
          console.error("Error flagging rating:", await response.json());
        }
      } catch (error) {
        console.error("Error flagging rating:", error);
      }
    },
    async deleteFlaggedRating(id, index) {
      try {
        const response = await fetch(
          `${baseURL.baseUrl}/delete-flagged-rating/${id}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.ok) {
          this.flaggedRatings.splice(index, 1); // Remove flagged rating from local data array
        } else {
          console.error(
            "Error deleting flagged rating:",
            await response.json()
          );
        }
      } catch (error) {
        console.error("Error deleting flagged rating:", error);
      }
    },
    confirmDeleteFlaggedRating(flaggedId, index) {
      if (confirm("Are you sure you want to delete this flagged comment?")) {
        this.deleteFlaggedRating(flaggedId, index);
      }
    },
    getFlagButtonText(rating) {
      return rating.is_flagged ? "Add to the List" : "Remove from the List";
    },
  },
  computed: {
    flaggedRatings() {
      return this.ratings.filter((rating) => rating.flagged_id);
    },
  },
};
</script>

<style scoped>
/* Add your scoped styles here */
</style>
