import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useLocalStorage } from '@vueuse/core';
export const mainStore = defineStore('mainStore', () => {
  let toastMsg = ref({ msg: '', type: '' });
  let timeOutId = ref(undefined);
  let token = ref(useLocalStorage('token', ''));
  let user = ref(useLocalStorage('user', {}));
  let selectedDistance = ref(useLocalStorage('selectedDistance', {}));
  let isAuthenticated = ref(useLocalStorage('isAuthenticated', false));
//   const modals = {
//     isAddProductModalOpen: ref(false),
//     isEditProductModalOpen: ref(false),
//     isEditStoreModalOpen: ref(false),
//     isAddStoreModalOpen: ref(false),
//     isEditSchoolModalOpen: ref(false),
//     isBlogEditRightSideModalOpen: ref(false),
//     schoolId: ref(useLocalStorage('schoolId', undefined)),
//     storeId: ref(useLocalStorage('storeId', undefined)),
//     productId: ref(useLocalStorage('productId', undefined)),
//     blogId: ref(useLocalStorage('blogId', undefined)),
//   };
//   const selectedSchoolFilterIds = ref(useLocalStorage('selectedSchoolFilterIds', {}));
//   const ImageArrayForSchool = ref(useLocalStorage('ImageArrayForSchool', []));
//   const locationArrayForAddSchool = ref(useLocalStorage('locationArrayForAddSchool', []));
  function setUser(val) {
    user.value = val;
  }
  function setToken(val) {
    token.value = val;
  }
  function setIsAuthenticated(val) {
    isAuthenticated.value = val;
  }
  function toast(msg, type) {
    toastMsg.value.msg = msg;
    toastMsg.value.type = type;
    let secs = undefined;
    if (type === 'success') {
      secs = 2000;
    } else {
      secs = 7000;
    }
    console.log('toast secs', secs);
    timeOutId.value = setTimeout(() => {
      toastMsg.value.msg = '';
      toastMsg.value.type = '';
      clearTimeout(timeOutId.value);
    }, secs);
  }
  function hasPermission(pid) {
    if (!pid) {
      return true;
    }
    return user.value.permissions?.includes(pid);
  }
  return {
    toastMsg,
    selectedDistance,
    hasPermission,
    setToken,
    setUser,
    setIsAuthenticated,
    user,
    toast,
    token,
    isAuthenticated,
  };
});