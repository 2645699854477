<template>
  <nav
    class="p-4 text-white bg-gradient-to-r from-gray-300 to-gray-300"
    @click="handleOutsideClick"
  >
    <div class="flex justify-between items-center">
      <div class="flex items-center">
        <button
          @click.stop="toggleMenu"
          class="focus:outline-none md:hidden mr-2"
        >
          <svg
            class="w-8 h-8 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16m-7 6h7"
            ></path>
          </svg>
        </button>
        <div class="sm:w-12 bg-white rounded-3xl w-9">
          <img src="../assets/MainLogo.png" alt="Main Logo" />
        </div>
        <router-link
          to="/"
          class="sm:text-3xl text-xl uppercase tracking-wide font-bold mt-1 mx-2"
        >
          Rate My Manager
        </router-link>
      </div>
      <div class="hidden md:flex items-center space-x-6">
        <!-- Always visible About tab -->
        <router-link
          to="/about"
          class="block hover:underline font-thin"
          @click="closeMenu"
        >
          <div class="flex items-center gap-3 my-3">
            <aboutLogo /> <span>About</span>
          </div>
        </router-link>

        <!-- Conditional navigation links -->
        <template v-if="isUserLoggedIn">
          <router-link v-if="isAdmin" to="/admin" class="hover:underline">
            <div class="flex items-center gap-1">
              <adminLogo /><span>Admin Profile</span>
            </div>
          </router-link>
          <router-link to="/add-manager" class="hover:underline">
            <div class="flex items-center gap-2">
              <addManager /> <span>Add Manager</span>
            </div>
          </router-link>
          <router-link
            to="/managers"
            class="block hover:underline font-thin"
            @click="closeMenu"
          >
            <div class="flex items-center gap-3 my-3">
              <managerLogo /> <span>Managers</span>
            </div>
          </router-link>
          <router-link
            to="/companies"
            class="block hover:underline font-thin"
            @click="closeMenu"
          >
            <div class="flex items-center gap-3 my-3">
              <companyLogo /> <span>Companies</span>
            </div>
          </router-link>
          <button
            @click="logout"
            class="px-4 py-2 bg-red-500 hover:bg-red-600 rounded-lg text-white"
          >
            Logout
          </button>
        </template>
        <template v-else>
          <button
            @click="showLoginModal"
            class="px-4 py-2 bg-blue-500 hover:bg-red-600 rounded-md text-white"
          >
            Login
          </button>
        </template>
      </div>
    </div>
    <!-- Mobile Menu -->
    <transition name="slide">
      <div
        v-if="isMenuOpen"
        class="md:hidden mt-4 space-y-2 bg-gray-800 p-4 rounded-lg"
        ref="menu"
      >
        <!-- Always visible About tab -->
        <router-link
          to="/about"
          class="block hover:underline font-thin"
          @click="closeMenu"
        >
          <div class="flex items-center gap-3 my-3">
            <aboutLogo /> <span>About</span>
          </div>
        </router-link>

        <!-- Conditional navigation links -->
        <template v-if="isUserLoggedIn">
          <router-link
            v-if="isAdmin"
            to="/admin"
            class="block hover:underline font-thin"
            @click="closeMenu"
          >
            <div class="flex items-center gap-1">
              <adminLogo /><span>Admin Profile</span>
            </div>
          </router-link>
          <router-link
            to="/managers"
            class="block hover:underline font-thin"
            @click="closeMenu"
          >
            <div class="flex items-center gap-3 my-3">
              <managerLogo /> <span>Managers</span>
            </div>
          </router-link>
          <router-link
            to="/companies"
            class="block hover:underline font-thin"
            @click="closeMenu"
          >
            <div class="flex items-center gap-3 my-3">
              <companyLogo /> <span>Companies</span>
            </div>
          </router-link>
          <router-link
            to="/add-manager"
            class="block hover:underline font-thin"
            @click="closeMenu"
          >
            <div class="flex items-center gap-3 my-3">
              <addManager /> <span>Add Manager</span>
            </div>
          </router-link>
          <button
            @click="logout"
            class="block w-full text-center px-4 py-2 bg-red-500 hover:bg-red-600 rounded-lg text-white"
          >
            Logout
          </button>
        </template>
        <template v-else>
          <button
            @click="showLoginModal"
            class="block w-full px-4 py-2 bg-blue-500 hover:bg-red-600 rounded-md text-white"
          >
            Login
          </button>
        </template>
      </div>
    </transition>
    <login
      v-if="isModalVisible"
      :initialTab="initialTab"
      @closeModal="isModalVisible = false"
    />
  </nav>
</template>

<script>
import login from "./Login/login-Form.vue";
import { mainStore } from "../stores/index";
import { useRouter } from "vue-router";
import addManager from "../assets/add_manager.vue";
import adminLogo from "../assets/admin_logo.vue";
import aboutLogo from "../assets/about_logo.vue";
import companyLogo from "../assets/company_logo.vue";
import managerLogo from "../assets/managers_logo.vue";

export default {
  components: {
    login,
    addManager,
    adminLogo,
    aboutLogo,
    companyLogo,
    managerLogo,
  },

  name: "HeaderPage",
  data() {
    return {
      isModalVisible: false,
      initialTab: null,
      isMenuOpen: false,
      router: useRouter(),
    };
  },
  computed: {
    user() {
      return mainStore().user;
    },
    isUserLoggedIn() {
      return this.user && Object.keys(this.user).length > 0; // Check if user is logged in
    },
    isAdmin() {
      return this.isUserLoggedIn && this.user.role === "admin"; // Check if the user is an admin
    },
  },
  methods: {
    showLoginModal() {
      this.isModalVisible = true;
    },
    logout() {
      mainStore().user = "";
      mainStore().token = "";
      localStorage.removeItem("authToken");
      this.isModalVisible = false;
      this.isMenuOpen = false;
      this.router.push("/");
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    handleOutsideClick(event) {
      if (this.isMenuOpen && !this.$refs.menu.contains(event.target)) {
        this.closeMenu();
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleOutsideClick);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleOutsideClick);
  },
};
</script>

<style scoped>
nav {
  background: linear-gradient(to right, #4b5563, #2d3748);
}

a {
  transition: color 0.2s;
}

a:hover {
  color: #cbd5e0;
}

button {
  transition: background-color 0.2s;
}

button:hover {
  background-color: #1e40af;
  border-radius: 10px;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s ease-out;
}
.slide-enter {
  transform: translateX(-100%);
}
.slide-leave-to {
  transform: translateX(-100%);
}
</style>
