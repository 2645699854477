<template>
  <div class="container mx-auto px-4 py-10">
    <h1 class="text-4xl font-bold text-center text-primary mb-6">
      About Rate My Manager
    </h1>
    <p class="text-center text-lg mb-6 text-secondary">
      It’s time for subordinates to rate back!
    </p>
    <p class="text-base mb-6">
      Typically managers hold all of the power of rating people below them. This
      tool provides a place to give feedback to managers and help people know
      what kind of manager they might be working with.
    </p>
    <div class="flex justify-center mb-6">
      <img
        src="@/assets/about-info.jpg"
        alt="Boss vs Leader"
        class="w-full max-w-md h-auto rounded-lg shadow-lg"
      />
    </div>
    <p class="text-base mb-6">
      Managers and management styles are extremely important to the
      effectiveness of the company and happiness of employees. The goal is to
      recognize the positive or negative influence of individual managers and
      create a more productive company as a whole.
    </p>
    <p class="text-base mb-6">
      All data is publicly populated and Rate My Manager takes no responsibility
      for posted ratings or the results of them. If you feel a post is
      inappropriate or drastically inaccurate, it can be flagged and enough
      flags will result in the post being removed.
    </p>
  </div>
</template>

<script>
export default {
  name: "AboutRateMyManager",
};
</script>

<style scoped>
.container {
  max-width: 1200px;
  margin: 0 auto;
}

h1 {
  font-family: "Helvetica Neue", Arial, sans-serif;
  color: #2c3e50; /* Dark blue */
}

.text-primary {
  color: #284051; /* Tailwind Blue */
}

.text-secondary {
  color: #7f8c8d; /* Tailwind Gray */
}

p {
  font-family: "Arial", sans-serif;
  line-height: 1.6;
  color: #2c3e50; /* Dark blue for text */
}

img {
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .text-lg {
    font-size: 1.125rem; /* Adjust font size for smaller screens */
  }

  .text-base {
    font-size: 1rem;
  }

  .container {
    padding: 0 1rem;
  }
}
</style>
