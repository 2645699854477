<template>
  <div>
    <ManagerForm @managerAdded="redirectToHome" />
  </div>
</template>

<script>
import ManagerForm from "../components/ManagerForm.vue";

export default {
  name: "AddManager",
  components: {
    ManagerForm,
  },
  methods: {
    redirectToHome() {
      this.$router.push("/");
    },
  },
};
</script>
