<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    height="20px"
    width="20px"
    version="1.1"
    id="Capa_1"
    viewBox="0 0 65.456 65.456"
    xml:space="preserve"
    :class="['svg-icon', svgClass]"
  >
    <g>
      <path
        d="M57.427,5.031C53.76,1.646,49.895,0,45.611,0c-5.052,0-9.663,2.314-14.123,4.553c-4.012,2.014-7.801,3.916-11.432,3.916   c-2.742,0-5.203-1.092-7.745-3.438c-0.875-0.808-2.146-1.021-3.238-0.543c-1.023,0.448-1.698,1.425-1.78,2.526   c-0.147,0.354-0.23,0.742-0.23,1.149v54.293c0,1.657,1.343,3,3,3s3-1.343,3-3V44.807c2.222,1.1,4.536,1.66,6.992,1.66   c0,0,0.001,0,0.002,0c5.051-0.001,9.662-2.314,14.122-4.553c4.013-2.014,7.803-3.915,11.434-3.915c2.742,0,5.203,1.092,7.744,3.438   c0.875,0.81,2.146,1.023,3.238,0.544c1.092-0.478,1.797-1.557,1.797-2.748V7.235C58.392,6.397,58.042,5.599,57.427,5.031z    M52.392,33.534C50.236,32.506,47.989,32,45.613,32c-5.052,0-9.664,2.314-14.125,4.553c-4.012,2.013-7.801,3.914-11.431,3.915   h-0.001c-2.393,0-4.572-0.833-6.778-2.605V12.934c2.156,1.029,4.403,1.535,6.779,1.535c5.052,0,9.664-2.314,14.123-4.553   C38.192,7.902,41.982,6,45.612,6c2.395,0,4.574,0.833,6.78,2.605V33.534z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  computed: {
    svgClass() {
      return {
        "fill-red-500 hover:fill-red-700 transition ease-in-out duration-300": true,
        "hover:scale-110 transform": true,
      };
    },
  },
};
</script>

<style scoped>
.svg-icon {
  transition: fill 0.3s ease-in-out, transform 0.3s ease-in-out;
}
.fill-red-500 {
  fill: #f00; /* Red */
}
.hover\:fill-red-800:hover {
  fill: #c00; /* Dark Red */
}
.hover\:scale-110:hover {
  transform: scale(1.1);
}
</style>

