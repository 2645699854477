<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="w-7 h-7 mr-2"
      fill="#0077B5"
      viewBox="0 0 24 24"
    >
      <path
        d="M22.23 0H1.77A1.771 1.771 0 000 1.771v20.457A1.771 1.771 0 001.77 24h20.457A1.771 1.771 0 0024 22.228V1.771A1.771 1.771 0 0022.23 0zM7.112 20.454H3.557V9h3.555v11.454zM5.335 7.556a2.065 2.065 0 110-4.13 2.065 2.065 0 010 4.13zm15.119 12.898h-3.554v-5.934c0-1.417-.028-3.242-1.976-3.242-1.977 0-2.28 1.544-2.28 3.14v6.036H9.09V9h3.41v1.563h.05c.474-.899 1.632-1.846 3.36-1.846 3.593 0 4.255 2.364 4.255 5.437v6.3z"
      />
    </svg>
  </div>
</template>
