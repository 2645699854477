<template>
  <div>
    <ProfileForm @profileCreated="redirectToHome" />
  </div>
</template>

<script>
import ProfileForm from "../components/ProfileForm.vue";

export default {
  name: "ProfilePage",
  components: {
    ProfileForm,
  },
  methods: {
    redirectToHome() {
      this.$router.push("/");
    },
  },
};
</script>
