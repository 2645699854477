<template>
  <div>
    <Header />
    <router-view></router-view>
    <FooterVue />
  </div>
</template>

<script>
import FooterVue from "./components/Footer.vue";
import Header from "./components/Header.vue";
export default {
  name: "App",
  components: {
    Header,
    FooterVue,
  },
};
</script>
<style>
html, body, #app {
  height: 100%;
  margin: 0;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  padding-bottom: 3rem; /* Make sure this matches the height of your footer */
}
</style>

