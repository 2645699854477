/* eslint-disable */
const helpers = {
  regex: {
    // eslint-disable-next-line
    email:
      /^[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$/,
    company: /^.{1,}$/,
    firstname: /^.{1,}$/,
    lastname: /^.{1,}$/,
    street: /^.{2,}$/,
    city: /^.{2,}$/,
    state: /^.{2,}$/,
    country: /^.{2,}$/,
    pin: /^[0-9]{4,}$/,
    district: /^.{1,}$/,
    password: /^.{1,}$/,
    //[1-9] is 1 and remaining 8,13 will give total of 9 to 14 digits mobile number
    // mobile: /^(^\+8|8|^09)(\d{3,4}-?){2}\d{3,4}$/,
    mobile: /^(?:\+?(\d{1,3}))?[-.\s]?(\d{3})[-.\s]?(\d{3})[-.\s]?(\d{4})$/,
    generalText: /^.{1,}$/,
    amount: /^\d{0,8}(\.\d{1,4})?$/, // floating number regex
    // nonZeroInt: /^[1-9][0-9]{0,}$/,
    nonZeroInt: /^(?=.)([+-]?([0-9]*)(\.([0-9]+))?)$/,
    image: /^.*\.(gif|jpe?g|bmp|png)/,

    time: /^([01]?[0-9]|2[0-3]):[0-5][0-9]/,
  },

  validation: {
    // Before final validation - set all to false
    // Because initially every field is set to isValid to not show error msg
    setAllToFalseTemporarily(formFields) {
      formFields.forEach((field) => {
        if (!field.isDisabled && field.isRequired) {
          console.log("setAllToFalseTemporarily formFields", field.isValid);
          field.isValid = false;
        }
      });
    },

    isSingleFieldValid(field, value) {
      console.log(`field, value`, field, value);
      if (value === "No") {
        value = "Not"; // regex.test("No") is always false | this wont affect the actual value going to be saved
      }

      if (Array.isArray(value)) {
        if (value.length > 0) {
          field.isValid = true;
        } else {
          field.isValid = false;
        }
      } else if (field.regex.test(value) && field.isRequired) {
        console.log("regex one field", value, field.regex);
        field.isValid = true;
        console.log("Valid 1");
      } else if (!field.isRequired) {
        field.isValid = true;
        console.log("Valid 2");
      } else {
        field.isValid = false;
        console.log("invalid");
      }

      if (!field.isValid) {
        return false;
      } else {
        return true;
      }
    },

    setFieldsValid(formFields, formData) {
      for (let i = 0; i < formFields.length; i += 1) {
        const field = formFields[i];
        const key = field.key;
        const value = formData[key];
        // console.log('regex', key, value, field, field.regex);
        if (Array.isArray(value)) {
          if (value.length > 0) {
            field.isValid = true;
          } else {
            field.isValid = false;
          }
        } else if (
          typeof value === "object" &&
          JSON.stringify(value) === "{}" &&
          field.isRequired
        ) {
          field.isValid = false;
        } else if (field.regex.test(value) && field.isRequired) {
          console.log("regex", key, value, field.regex);
          field.isValid = true;
        } else if (!field.isRequired) {
          field.isValid = true;
        } else {
          field.isValid = false;
        }
      }
    },

    isAllFieldsValid(formFields, formData) {
      this.setAllToFalseTemporarily(formFields);
      console.log(
        "this.setAllToFalseTemporarily(formFields);",
        this.setAllToFalseTemporarily(formFields)
      );
      let isAllValid = true;
      for (let i = 0; i < formFields.length; i += 1) {
        const field = formFields[i];
        const key = field.key;
        let value = formData[key];
        if (typeof value === "string") {
          // for undefined, number values, trim should not be called
          value = value.trim();
        }
        console.log("key, value", key, value);
        if (!field.isRequired || field.regex.test(value)) {
          field.isValid = true;
        } else {
          field.isValid = false;
          isAllValid = false;
        }
      }
      return isAllValid;
    },
  },

  pageNavigation: {
    setPagesCount(dataArray, maxRecordsPerpage) {
      // dataArray.length cant be taken as max length
      // as last page can have records less than actual length.
      // ex: actual length = 25 and last page = 4 then 12000/25 !== 12000/4
      // dataArray can be tickets, assets, spares etc
      const firstRow = dataArray[0] || {};
      console.log(`dataArray[0]`, dataArray[0]);

      const totalCount = parseInt(firstRow.count);
      console.log(`totalCount`, totalCount);
      console.log(`table rows per page`, maxRecordsPerpage);
      const PagesCount = totalCount / maxRecordsPerpage || 1;
      console.log(`totalPagesCount`, Math.ceil(PagesCount));
      return Math.ceil(PagesCount);
    },
  },
};

export default helpers;
